import API from '../components/api';
import {isEmpty, formatComma} from '../components/tools';

import SupertriRanking from '../components/supertriRanking.js';
import Leaderboard from '../components/leaderboard.js';

//const sortRankingTypesWeights = {
//	overall: 1,
//	swim: 2,
//	bike: 3,
//	run: 4,
//};
//const sortRankingsWeights = {
//	individual_series: 1,
//	team_series: 2,
//};

export default (template) => {
	return {
		template,
		components: {
			supertriRanking: SupertriRanking(require('/src/components/supertriRanking.html')),
			leaderboard: Leaderboard(require('/src/components/leaderboard.html')),
		},
		watch: {
			'$reloadKey.count'() {
				this.notify();
			},
			'$route.params.eventId'() {
				this.onNewEventId();
			},
			'$route.params.tabId'() {
				this.onNewTabId();
			},
			'$route.params.option'() {
				this.onNewOption();
			},
			'$route.params.leaderboard'() {
				this.onNewLeaderBoard();
			},
		},
		data() {
			return {
				events: null,
				eventsLoading: false,
				eventsError: null,
				currentEvent: null,
				currentStartList: null,
				secondTabs: null,
				thirdTabs: null,
				forthTabs: null,
				currentLeaderboard: null,
				currentRanking: null,
				rankingLoading: false,
				rankingError: null,
			};
		},
		async created() {
			await this.fetchEvents();

			this.openBestTab();

			this.onNewEventId();

			this.onNewTabId();

			this.onNewOption();
		},
		computed: {
			leagueTabs() {
				const tabs = [
					{idx: 'team', name: 'team', condition: () => true},
					{idx: 'individual', name: 'individual', condition: () => true},
					{idx: 'swim', name: 'swim', condition: () => true},
					{idx: 'bike', name: 'bike', condition: () => true},
					{idx: 'run', name: 'run', condition: () => true},
				];
				return tabs;
			},
			eventTabs() {
				const tabs = [
					{idx: 'startlist', name: 'startlist', condition: () => true},
					{idx: 'men', name: 'men', condition: () => true},
					{idx: 'women', name: 'women', condition: () => true},
					{idx: 'team', name: 'team', condition: () => true},
				];
				return tabs;
			},
			genderTabs() {
				const tabs = [
					{idx: 'men', name: 'men', condition: () => true},
					{idx: 'women', name: 'women', condition: () => true},
				];
				return tabs;
			},
			rankinksTabs() {
				const tabs = [
					{idx: 'individual', name: 'individual', condition: () => true},
					{idx: 'swim', name: 'swim', condition: () => true},
					{idx: 'bike', name: 'bike', condition: () => true},
					{idx: 'run', name: 'run', condition: () => true},
					{idx: 'leaderboard', name: 'leaderboard', condition: () => this.$leaderboard.enabled === true},
				];
				return tabs;
			},
			leaderboardTabs() {
				const tabs = [
					{idx: 'live', name: 'live', condition: () => true},
					{idx: 'details', name: 'details', condition: () => true},
				];
				return tabs;
			},
		},
		methods: {
			formatComma,
			onNewEventId() {
				console.log('onNewEventId!', this.$route.params.eventId);
				this.secondTabs = null;

				if (isEmpty(this.$route.params.eventId) === true) {
					this.openBestTab();
					return;
				}

				if (this.$route.params.eventId === 'league') {
					this.secondTabs = this.leagueTabs;

					if (this.isEmpty(this.$route.params.tabId) === true) {
						return this.$router.replace({params: {...this.$route.params, tabId: 'team'}});
					} else {
						this.onNewTabId();
					}
				} else {
					this.secondTabs = this.eventTabs;
					this.setEvent();

					if (this.isEmpty(this.$route.params.tabId) === true) {
						return this.$router.replace({params: {...this.$route.params, tabId: 'startlist'}});
					} else {
						this.onNewTabId();
					}
				}
			},
			async onNewTabId() {
				console.log('onNewTabId!', this.$route.params.tabId);

				this.thirdTabs = null;
				this.forthTabs = null;
				this.currentStartList = null;
				this.currentRanking = null;
				this.currentLeaderboard = null;

				if (this.$route.params.eventId === 'league') {
					if (this.isEmpty(this.$route.params.tabId) === true) {
						return this.$router.replace({params: {...this.$route.params, tabId: 'team'}});
					}

					if (this.$route.params.tabId === 'team') {
						await this.fetchRanking('league', 'TEAM_LEAGUE_OVERALL_2024');
					} else {
						this.thirdTabs = this.genderTabs;

						if (this.isEmpty(this.$route.params.option) === true) {
							return this.$router.replace({params: {...this.$route.params, option: 'men'}});
						}
					}
				} else {
					if (this.$route.params.tabId === 'startlist') {
						this.thirdTabs = this.genderTabs;

						if (this.isEmpty(this.$route.params.option) === true) {
							return this.$router.replace({params: {...this.$route.params, option: 'men'}});
						} else {
							this.onNewOption();
						}
					}
					if (this.$route.params.tabId === 'team') {
						await this.fetchRanking('ranking', `${this.$route.params.eventId}_EVENT_TEAM_OVERALL`);
					}
					if (this.$route.params.tabId === 'men' || this.$route.params.tabId === 'women') {
						this.thirdTabs = this.rankinksTabs;

						if (this.isEmpty(this.$route.params.option) === true) {
							return this.$router.replace({params: {...this.$route.params, option: 'individual'}});
						} else {
							this.onNewOption();
						}
					}
				}
			},
			async onNewOption() {
				console.log('onNewOption', this.$route.params.option);

				this.forthTabs = null;
				this.currentStartList = null;
				this.currentRanking = null;
				this.currentLeaderboard = null;

				if (this.$route.params.eventId === 'league') {
					if (isEmpty(this.$route.params.option) === false) {
						if (this.$route.params.tabId === 'individual') {
							await this.fetchRanking('league', `INDIVIDUAL_LEAGUE_OVERALL_${this.$route.params.option.charAt(0).toUpperCase()}_2024`);
						} else {
							const tabId = this.$route.params.tabId === 'individual' ? 'overall' : this.$route.params.tabId;
							const id = `INDIVIDUAL_LEAGUE_${tabId.toUpperCase()}_${this.$route.params.option.charAt(0).toUpperCase()}_2024`;
							await this.fetchRanking('league', id);
						}
					}
				} else {
					if (this.$route.params.tabId === 'startlist' && isEmpty(this.$route.params.option) === false) {
						if (this.isEmpty(this.currentEvent.startList) === false) {
							const startList = this.currentEvent.startList[this.$route.params.option.charAt(0)];

							let teams = {};
							for (const list of startList) {
								this.currentStartList = list;
								if (typeof teams[list.teamId] === 'undefined') {
									teams[list.teamId] = [];
								}
								teams[list.teamId].push(list);
							}
							this.currentStartList = teams;
						}
					}

					if (this.$route.params.tabId === 'men' || this.$route.params.tabId === 'women') {
						if (isEmpty(this.$route.params.option) === false) {
							if (this.$route.params.option === 'leaderboard') {
								this.forthTabs = this.leaderboardTabs;

								if (this.isEmpty(this.$route.params.leaderboard) === true) {
									return this.$router.replace({params: {...this.$route.params, leaderboard: 'live'}});
								}

								const id = `${this.$route.params.eventId}_${this.$route.params.tabId.charAt(0).toUpperCase()}_${this.$route.params.option.toUpperCase()}`;
								await this.fetchLeaderboard(id);
							} else {
								const option = this.$route.params.option === 'individual' ? 'overall' : this.$route.params.option;
								const id = `${this.$route.params.eventId}_${this.$route.params.tabId.charAt(0).toUpperCase()}_EVENT_INDIVIDUAL_${option.toUpperCase()}`;
								await this.fetchRanking('ranking', id);
							}
						}
					}
				}
			},
			async onNewLeaderBoard(force = false) {
				console.log('onNewLeaderBoard', this.$route.params.leaderboard);

				if (isEmpty(this.$route.params.leaderboard) === false) {
					if (this.currentLeaderboard === null || force === true) {
						const id = `${this.$route.params.eventId}_${this.$route.params.tabId.charAt(0).toUpperCase()}_${this.$route.params.option.toUpperCase()}`;
						await this.fetchLeaderboard(id);
					}
			}
			},
			setEvent() {
				console.log('setEvent!', this.$route.params.eventId);

				if (isEmpty(this.$route.params.eventId) === true) {
					this.openBestTab();
				} else {
					this.currentEvent = this.events.find((e) => e.id === this.$route.params.eventId);
					if (isEmpty(this.currentEvent) === false) {
						document.title = this.Config.appName + ' - ' + this.currentEvent.id;
					}
				}
			},
			async fetchEvents() {
				this.eventsLoading = false;
				this.eventsError = null;

				try {
					this.eventsLoading = true;
					this.events = await API.getEvents();
					this.eventsLoading = false;
				} catch (exception) {
					this.eventsError = exception.message;
					this.eventsLoading = false;
				}
			},
			async fetchLeaderboard(id) {
				this.rankingLoading = false;
				this.rankingError = null;

				try {
					this.rankingLoading = true;
					this.currentLeaderboard = await API.getLeaderboard(id);
					if (this.isEmpty(this.currentLeaderboard) === false) {
						document.title = this.Config.appName + ' - ' + this.currentLeaderboard.id;
					}
					this.rankingLoading = false;
				} catch (exception) {
					this.rankingError = exception.message;
					this.rankingLoading = false;
				}
			},
			openBestTab() {
				if (this.isEmpty(this.$route.params.eventId) === true) {
					const now = new Date();

					let minDiff = -Infinity;
					let defaultEventId = null;

					for (let event of this.events) {
						const diff = (new Date(event.date) - now) / 1000;
						if (diff <= 0 && diff > minDiff) {
							minDiff = diff;
							defaultEventId = event.id;
						}
					}

					if (defaultEventId === null) {
						defaultEventId = this.events[0].id;
					}
					return this.$router.replace({params: {...this.$route.params, eventId: defaultEventId}});
				}
			},
			async fetchRanking(type, id) {
				this.rankingLoading = false;
				this.rankingError = null;

				try {
					this.rankingLoading = true;

					if (type === 'ranking') {
						this.currentRanking = await API.getRanking(id);
						if (this.isEmpty(this.currentRanking) === false) {
							document.title = this.Config.appName + ' - ' + this.currentRanking.id;
						}
					} else if (type === 'league') {
						this.currentRanking = await API.getLeague(id);
						if (this.isEmpty(this.currentRanking) === false) {
							document.title = this.Config.appName + ' - ' + this.currentRanking.id;
						}
					}
					this.rankingLoading = false;

				} catch (exception) {
					this.rankingError = exception.message;
					this.currentRanking = null;
					this.rankingLoading = false;
				}
			},
			async notify() {
				console.log('notify!', this.$reloadKey);

				if (isEmpty(this.$reloadKey.message) === true) {
					return;
				}

				if (this.$reloadKey.message.type === 'events' && this.$reloadKey.message.id === this.$route.params.eventId) {
					if (this.$reloadKey.message.method === 'remove') {
						this.$router.push('/');
					}
					if (this.$reloadKey.message.method === 'patch') {
						await this.fetchEvents();
						this.setEvent();
					}
				}

				if (this.$reloadKey.message.type === 'rankings' && this.$reloadKey.message.id.startsWith(this.$route.params.eventId) === true) {
					this.onNewEventId();
					this.onNewTabId();
					this.onNewOption();
				}

				if (this.$reloadKey.message.type === 'leaderboards' && this.$reloadKey.message.id.startsWith(this.$route.params.eventId) === true) {
					this.onNewEventId();
					this.onNewTabId();
					this.onNewOption();
				}

				if (this.$reloadKey.message.type === 'leagues' && this.$reloadKey.message.id.startsWith(this.$route.params.eventId) === true) {
					this.onNewEventId();
					this.onNewTabId();
					this.onNewOption();
				}
			},
		},
	};
};
