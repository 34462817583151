import {isEmpty} from '../components/tools';

export default (template) => {

	return {
		template,
		props: {
			backgroundColor: String,
			image: String,
			imageLink: String,
			home: String,
		},
		watch: {
			async '$reloadKey.count'() {
				//this.notify();
			},
		},
		computed: {
			getBgColor() {
				if (isEmpty(this.backgroundColor) === false) {
					return '#' + this.backgroundColor;
				}
				return '#d6d6d6';
			},
			getHeaderImage() {
				if (isEmpty(this.image) === false) {
					return this.image;
				}
				return null;
			},
			getHeaderImageLink() {
				if (isEmpty(this.imageLink) === false) {
					return this.imageLink;
				}
				return null;
			},
		},
		data() {
			return {
			};
		},
		methods: {
			toggleTheme() {
				this.toggleDarkMode(!this.$theme.dark, true);
			},
		},
	};
};
