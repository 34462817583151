
const validTypes = [
	'swim',
	'run',
	'bike',
];
export default (template) => {
	return {
		template,
		watch: {
			ranking() {
				const openToggles = this.$el.querySelectorAll('div[type="button"] i.fa-chevron-up');
				for (let openToggle of openToggles) {
					this.toggleDetails({currentTarget: openToggle.parentNode});
				}
			},
		},
		props: {
			ranking: Object,
			openRider: String,
		},
		data() {
			return {
			};
		},
		methods: {
			hasField(field) {
				if (this.isEmpty(this.ranking.rankings) === true) {
					return false;
				}
				return this.isEmpty(Object.keys(this.ranking.rankings[0]).find((r) => r === field)) === false;
			},
			toggleDetails(el) {
				const currentTarget = el.currentTarget;
				const collapseTargetId = el.currentTarget.getAttribute('data-mdb-target').replace('#', '');
				const targetCollapse = document.getElementById(collapseTargetId);

				if (targetCollapse.classList.contains('show') === false) {
					currentTarget.querySelector('i').classList.add('fa-chevron-up');
					currentTarget.querySelector('i').classList.remove('fa-chevron-down');
				} else {
					currentTarget.querySelector('i').classList.add('fa-chevron-down');
					currentTarget.querySelector('i').classList.remove('fa-chevron-up');
				}

				targetCollapse.classList.toggle('show');
			},
			isValidType(type) {
				if (typeof type !== 'string') {
					return null;
				}

				if (validTypes.includes(type.toLowerCase()) === true) {
					return type.toLowerCase();
				}
				return null;
			},
		},
	};
};
