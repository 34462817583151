
export default () => {
	return {
		template: '<span v-if="isEmpty(pts) === false" class="text-center d-inline-block px-1 py-1 fw-bold" style="min-width: 40px; color: black;">{{displayPoints(pts)}}</span>',
		props: {
			pts: Number,
		},
		data() {
			return {
			};
		},
		created() {
		},
		methods: {
			displayPoints(points) {
				if (this.isEmpty(points) === true) {
					return '';
				}
				return points;
			},
		},
	};
};
