import {createApp, reactive} from 'vue/dist/vue.esm-bundler';
import {createRouter, createWebHistory} from 'vue-router';
import VueMatomo from 'vue-matomo';

// modules
import RealTime from './modules/realtime';
import i18n from './modules/i18n';

// components
import Config from './components/config';
import {isEmpty, formatDate, capitalize} from './components/tools';
import Header from './components/header';
import Footer from './components/footer';
import Loader from './components/loader';
import Icon from './components/icon';
import Point from './components/point';
import Flag from './components/flag';
import Athlete from './components/athlete';
// pages
import Home from './pages/home';

const routes = [
	{path: '/:eventId?/:tabId?/:option?/:leaderboard?', component: Home(require('/src/pages/home.html')), name: 'home'},
	{path: '/leaderboard', redirect: '/'},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to) => {
	if (to.redirectedFrom && to.redirectedFrom.path === '/leaderboard') {
		app.config.globalProperties.$leaderboard.enabled = !app.config.globalProperties.$leaderboard.enabled;
		window.localStorage.setItem('displayLeaderBoard', app.config.globalProperties.$leaderboard.enabled);
	}
	return true;
});

const app = createApp();
// set reload key
RealTime(app.config.globalProperties);

app.config.globalProperties.$capitalize = capitalize;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.isEmpty = isEmpty;
app.config.globalProperties.Config = Config;
app.config.globalProperties.$rider = reactive({
	rider: null,
	details: null,
});

app.config.globalProperties.isModeIframe = () => {
	switch (window.location.hostname) {
		case 'iframe.localhost':
		case 'iframe.supertri.matsport.com':
		case 'iframe.test.supertri.matsport.com':
			return true;
	}
	return false;
};

app.config.globalProperties.$theme = reactive({
	dark: true,
});

const toggleDarkMode = (state, save) => {
	//console.log('toggleDarkMode', state);
	app.config.globalProperties.$theme.dark = state;
	if (state === true) {
		document.body.classList.add('dark-theme');
	} else {
		document.body.classList.remove('dark-theme');
	}
	if (save === true) {
		window.localStorage.setItem('darkTheme', String(state));
	}
};
app.config.globalProperties.toggleDarkMode = toggleDarkMode;

if (typeof window.matchMedia === 'function') {
	const match = window.matchMedia('(prefers-color-scheme: dark)');
	toggleDarkMode(match.matches);

	match.addEventListener('change', () => {
		toggleDarkMode(match.matches);
	});
}

if (typeof window.localStorage.getItem('darkTheme') === 'string') {
	toggleDarkMode(window.localStorage.getItem('darkTheme') === 'true');
}

app.config.globalProperties.$leaderboard = reactive({
	enabled: false,
});
if (typeof window.localStorage.getItem('displayLeaderBoard') === 'string') {
	app.config.globalProperties.$leaderboard.enabled = window.localStorage.getItem('displayLeaderBoard') === 'true';
}

app.use(i18n);
app.use(router);

if (Config.isProd === true && isEmpty(Config.statsSiteId) === false) {
	app.use(VueMatomo, {
		host: Config.statsURL,
		siteId: Config.statsSiteId,
		router,
	});
}

// components
app.component('cyc-header', Header(require('/src/components/header.html')));
app.component('cyc-footer', Footer(require('/src/components/footer.html')));
app.component('cyc-loader', Loader());
app.component('cyc-flag', Flag());
app.component('tri-icon', Icon());
app.component('tri-point', Point());
app.component('tri-athlete', Athlete(require('/src/components/athlete.html')));

app.mount('#app');
