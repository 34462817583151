
const validTypes = [
	'overall',
	'team',
	'swim',
	'run',
	'bike',
];

export default () => {
	return {
		template: '<img v-if="myType" :src="`/images/supertri-icons/${myType}.svg`" :style="`height: ${myHeight}px`">',
		props: {
			type: String,
			ranking: String,
			height: Number,
		},
		computed: {
			myHeight() {
				if (typeof this.height !== 'number') {
					return 25;
				}
				return parseInt(this.height);
			},
			myType() {
				if (typeof this.type !== 'string') {
					return null;
				}

				if (['team_series', 'team_event'].includes(this.ranking) === true) {
					return 'team';
				}

				if (validTypes.includes(this.type.toLowerCase()) === true) {
					return this.type.toLowerCase();
				}
				return null;
			},
		},
	};
};
