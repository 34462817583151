
export const isEmpty = (data) => {
	if (data === null) {
		return true;
	}
	if (typeof data === 'undefined') {
		return true;
	}
	if (typeof data.length === 'number' && data.length === 0) {
		return true;
	}
	if (typeof data === 'number' && data === 0) {
		return true;
	}
	if (data instanceof HTMLElement) {
		return false;
	}
	if (typeof data === 'object' && Object.keys(data).length === 0) {
		return true;
	}
	return false;
};

export const twoChars = (val) => {
	return ('0' + val).slice(-2);
};

export const formatDate = (value, format = 'YYYY-MM-DD') => {
	if (isEmpty(value) === true) {
		return value;
	}

	// fix dates with number (like 2022 => 1970)
	if (typeof value === 'number') {
		value = String(value);
	}

	const date = value === 'now' ? new Date() : new Date(value);

	//console.log('formatDate:', value, date, format);

	switch (format) {
		case 'YY':
			return `${String(date.getFullYear()).substr(2, 2)}`;
		case 'YYYY':
			return `${date.getFullYear()}`;
		case 'MM/DD':
			return `${twoChars(date.getMonth() + 1)}/${twoChars(date.getDate())}`;
		case 'DD/MM':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}`;
		case 'DD/MM/YY':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}/${date.getFullYear()}`;
		case 'YYYY-MM-DD':
			return `${date.getFullYear()}-${twoChars(date.getMonth() + 1)}-${twoChars(date.getDate())}`;
	}
	return value;
};

export const formatComma = (value) => String(value).replace('.', ',');

export const capitalize = (string) => {
	if (isEmpty(string) === true) {
		return string;
	}
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
