import { convertIocCode } from 'convert-country-codes';
export default () => {
	return {
		template: `<template v-if="isEmpty(iso2) === false"><img :src="'/images/iso-flag-png/' + iso2 + '.png'" :alt="nationality" class="image-flag" :style="'width: ' + this._width  + 'px;'"></template>`,
		watch: {
			nationality() {
				this.display();
			},
			width() {
				this.display();
			},
		},
		props: {
			nationality: String,
			width: Number,
		},
		data() {
			return {
				_width: 12,
				iso2: null,
			};
		},
		created() {
			this.display();
		},
		methods: {
			display() {
				this.iso2 = null;

				const country = convertIocCode(this.nationality);
				if (this.isEmpty(country) === false) {
					this.iso2 = country.iso2.toLowerCase();
				}

				if (this.width) {
					this._width = this.width;
				}
			},
		},
	};
};
