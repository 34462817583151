
export default (template) => {
	return {
		template,
		props: {
			athlete: Object,
			team: Object,
			teamLogoWidth: Number,
		},
		data() {
			return {
				width: 35,
			};
		},
		created() {
		},
		methods: {
		},
	};
};
