import Config from './config';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';

const app = feathers();
const restClient = rest(Config.serverRest);
app.configure(restClient.fetch(window.fetch.bind(window)));

export default {
	async getEvents(query) {
		try {
			return await app.service('events').find({
				query: {
					'$sort': {date: 1},
					...query,
				},
			});
		} catch (exception) {
			throw new Error('Invalid request');
		}
	},
	async getEvent(id, query = {}) {
		try {
			return await app.service('events').get(id, {
				query: {
					...query,
				},
			});
		} catch (exception) {
			throw new Error('Invalid request');
		}
	},
	async getRankings(query) {
		try {
			return await app.service('rankings').find({
				query: {
					...query,
				},
			});
		} catch (exception) {
			throw new Error('Invalid request');
		}
	},
	async getRanking(id, query = {}) {
		try {
			return await app.service('rankings').get(id, {
				query: {
					...query,
				},
			});
		} catch (exception) {
			if (exception.code === 404) {
				return null;
			}
			throw new Error('Invalid request');
		}
	},
	async getLeaderboard(id, query = {}) {
		try {
			return await app.service('leaderboards').get(id, {
				query: {
					...query,
				},
			});
		} catch (exception) {
			if (exception.code === 404) {
				return null;
			}
			throw new Error('Invalid request');
		}
	},
	async getLeaderboards(query) {
		try {
			return await app.service('leaderboards').find({
				query: {
					...query,
				},
			});
		} catch (exception) {
			throw new Error('Invalid request');
		}
	},
	async getLeague(id, query = {}) {
		try {
			return await app.service('leagues').get(id, {
				query: {
					...query,
				},
			});
		} catch (exception) {
			if (exception.code === 404) {
				return null;
			}
			throw new Error('Invalid request');
		}
	},
	async getRiderDetails(uciid) {
		const response = await fetch(Config.serverRest + '/riders/' + uciid + '?withDetails=true');
		if (response.status === 200) {
			const body = await response.json();
			return body;
		}

		throw new Error('Invalid request');
	},

};
