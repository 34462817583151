export default (template) => {

	return {
		template,
		updated() {
//			window.setTimeout(this.refreshMainMarginBottom, 500);
		},
		created() {
//			window.setTimeout(this.refreshMainMarginBottom, 500);
		},
		data() {
			return {
			};
		},
		methods: {
			refreshMainMarginBottom() {
				if (this.isModeIframe() === true) {
					return;
				}
				if (document.querySelector('main') === null) {
					window.setTimeout(this.refreshMainMarginBottom, 500);
					return;
				}
				try {
					const mainTop = getComputedStyle(document.querySelector('main'))['margin-bottom'];
					const imgHeight = getComputedStyle(document.querySelector('footer'))['height'];
					document.querySelector('main').style.marginBottom = (parseInt(mainTop) + parseInt(imgHeight)) + 'px';
					//console.log(getComputedStyle(document.querySelector('main'))['margin-bottom']);

				} catch (exception) {
					window.setTimeout(this.refreshMainMarginBottom, 500);
					return;
				}
			},
		},
	};
}
