import SupertriRanking from './supertriRanking.js';
export default (template) => {
	return {
		template,
		components: {
			supertriRanking: SupertriRanking(require('/src/components/supertriRanking.html')),
		},
		watch: {
			'$route.params.leaderboard'() {
				const openToggles = this.$el.querySelectorAll('div[type="button"] i.fa-chevron-up');
				//console.log('openToggles:', openToggles);

				for (let openToggle of openToggles) {
					this.toggleDetails({currentTarget: openToggle.parentNode.parentNode});
				}

				if (this.$route.params.leaderboard === 'live') {
					const firstToggle = this.$el.querySelectorAll('div[type="button"] i');
					if (this.isEmpty(firstToggle[0]) === false) {
						this.toggleDetails({currentTarget: firstToggle[0].parentNode.parentNode});
					}
				}
			},
		},
		props: {
			leaderboard: Object,
		},
		data() {
			return {
			};
		},
		created() {
		},
		methods: {
			toggleDetails(el) {
				const currentTarget = el.currentTarget;
				const collapseTargetId = el.currentTarget.getAttribute('data-mdb-target').replace('#', '');
				const targetCollapse = document.getElementById(collapseTargetId);
				//console.log(currentTarget, collapseTargetId, targetCollapse);

				if (targetCollapse.classList.contains('show') === false) {
					currentTarget.querySelector('i').classList.add('fa-chevron-up');
					currentTarget.querySelector('i').classList.remove('fa-chevron-down');
				} else {
					currentTarget.querySelector('i').classList.add('fa-chevron-down');
					currentTarget.querySelector('i').classList.remove('fa-chevron-up');
				}

				targetCollapse.classList.toggle('show');
			},
			filterLiveLeaderBoards(boards) {
				return boards
						.filter((b) => this.isEmpty(b.rankings) === false)
						.sort((b1, b2) => b2.distance - b1.distance)
						.slice(0, 2);
			},
		},
	};
};
